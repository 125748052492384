import $ from 'jquery';
import Foundation from 'foundation-sites';

const base = document.getElementById('base-content') ?? document.body;
const $iconProcessed = $('i.icon-status:not(.processed)');
let $popup: JQuery;
let $iconCurrent: JQuery;
let timer: NodeJS.Timeout;
let flag = false;

const statusOn = (): void => {
    if (flag && $popup) {
        $popup.remove();
    }
    flag = true;
    const $canvasContent = $('#oc-content');
    const baseRect = base.getBoundingClientRect();
    const $statusIcon = $iconCurrent.clone().css('top', '0');
    const status = $iconCurrent.data('status');
    const name = $iconCurrent.data('name');
    const date = $iconCurrent.data('date');

    $canvasContent.css('position', 'relative');
    if (!$canvasContent.find('small').length) {
        $canvasContent.append('<small/>');
    }

    $popup = $canvasContent.find('small');
    $popup.html('');

    let statusText = 'расширенным функционалом. ';
    let statusRef = '<a class="company-status__ref" target="_blank" rel="nofollow" ' +
        'href="https://www.elec-co.ru/companies_catalogue" ' +
        '>Ознакомиться с возможностями существующих пакетов.</a>';

    if (status > 3) {
        statusText = 'максимально возможным функционалом. Количество пакетов ограничено. ' +
            'Для получения информации о возможностях данного пакета услуг ';
        statusRef = '<a class="company-status__ref" target="_blank" rel="nofollow" ' +
            'href="https://www.elec-co.ru/companies_catalogue" ' +
            '>свяжитесь с нашими сотрудниками.</a>';
    }

    const canvasContentOffsetTop: number = $canvasContent.offset()?.top ?? 0;
    const canvasContentOffsetLeft: number = $canvasContent.offset()?.left ?? 0;
    const iconCurrentOffsetTop: number = $iconCurrent.offset()?.top ?? 0;
    const iconCurrentOffsetLeft: number = $iconCurrent.offset()?.left ?? 0;

    let statusX = 15;
    const statusY: number = iconCurrentOffsetTop - canvasContentOffsetTop + 18;
    const popupWidth = 350;

    if (Foundation.MediaQuery.atLeast('medium')) {
        statusX = iconCurrentOffsetLeft - canvasContentOffsetLeft;
        if ((iconCurrentOffsetLeft + popupWidth) > (baseRect.left + baseRect.width)) {
            statusX = statusX - 200;
        }
    }

    $popup.css({ 'top': statusY, 'left': statusX })
        .append(
            `<strong>Компания</strong> ${name} представлена в каталоге ${date}.<br>`,
            '<strong>Статус</strong> ',
            $statusIcon,
            ` означает, что компания использует платный пакет услуг Elec.ru с ${statusText} ${statusRef}`
        )
        .addClass('company-status')
        .on('click', (e) => {
            e.stopPropagation();
        });
};

const statusOff = (): void => {
    clearTimeout(timer);
    timer = setTimeout(popupRemove, 100);
    if ($popup) {
        $popup.on({
            mouseenter: () => {
                clearTimeout(timer);
            },
            mouseleave: () => {
                popupRemove();
            }
        });
    }
};

const actionTouch = (): void => {
    $iconProcessed.on('touchstart', function () {
        $iconCurrent = $(this);
        if (!$(this).hasClass('toched')) {
            $(this).addClass('toched');
            statusOn();
        } else {
            $(this).removeClass('toched');
            statusOff();
        }
    });
};

const popupRemove = (): void => {
    flag = false;
    if ($popup) {
        $popup.remove();
    }
};

function companyIconStatus(): void {
    $iconProcessed
        .addClass('processed')
        .on('click', (e) => {
            e.stopPropagation();
        });

    if (Foundation.MediaQuery.atLeast('large')) {
        actionTouch();
        $iconProcessed.on({
            mouseenter: function (): void {
                $iconCurrent = $(this);
                statusOn();
            },
            mouseleave: function (): void {
                $iconCurrent = $(this);
                statusOff();
            }
        });
    } else {
        actionTouch();
    }
}

export { companyIconStatus };
